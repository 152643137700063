import { Guid } from "guid-typescript";

export default function replaceIds<T>(item: any, map?: Map<string, string>): T {
  if (typeof item !== "object") {
    return item;
  }
  if (!map) {
    map = new Map<string, string>();
  }
  const ret: { [key: string]: any } = {};
  for (const key of Object.keys(item)) {
    if (key === "id") {
      if (item[key] && !map.has(item[key])) {
        map.set(item[key], Guid.create().toString());
      }
      ret[key] = map.get(item[key]);
    } else if (Array.isArray(item[key])) {
      ret[key] = item[key].map((i: any) => replaceIds(i, map));
    } else if (item[key] && typeof item[key] === "object") {
      ret[key] = replaceIds(item[key], map);
    } else if (
      item[key] &&
      key.endsWith("Id") &&
      /(^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$)/.test(
        item[key]
      )
    ) {
      if (!map.has(item[key])) {
        map.set(item[key], Guid.create().toString());
      }
      ret[key] = map.get(item[key]);
    } else {
      ret[key] = item[key];
    }
  }
  return ret as T;
}
