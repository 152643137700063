
































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DataProvider from "@/lib/DataProvider";
import { Questionnaire } from "@/data/models/Questionnaires";
import ICrudClient from "@/lib/ICrudClient";
import { Guid } from "guid-typescript";
import replaceIds from "@/lib/replaceIds";

@Component({
  components: {}
})
export default class CopyQuestionnaireDialog extends Vue {
  @Prop({ default: () => false })
  public show!: boolean;

  @Prop({ default: () => null })
  public questionnaire!: Questionnaire;

  @Prop()
  public crud!: ICrudClient<Questionnaire>;

  private copyNewName = "";
  private loading = false;

  private async createCopy() {
    this.loading = true;
    try {
      const newQuestionnaire = replaceIds<Questionnaire>(
        JSON.parse(JSON.stringify(this.questionnaire))
      );
      newQuestionnaire.name = this.copyNewName;
      await this.crud.addAsync([newQuestionnaire]);
      this.copyNewName = "";
      this.$emit("update:show", false);
      this.$emit("updated");
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
