









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DataProvider from "@/lib/DataProvider";
import { Questionnaire } from "@/data/models/Questionnaires";
import ICrudClient from "@/lib/ICrudClient";
import { Guid } from "guid-typescript";
import replaceIds from "@/lib/replaceIds";
import { readFileAsTextAsync } from "@/lib/readFile";

@Component({
  components: {},
})
export default class ImportQuestionnaireDialog extends Vue {
  @Prop({ default: () => false })
  public show!: boolean;

  @Prop({ default: () => null })
  public questionnaire!: Questionnaire;

  @Prop()
  public crud!: ICrudClient<Questionnaire>;
  private file: File | null = null;
  private copyNewName = "";
  private loading = false;

  readFileAsync(file: File): Promise<string> {
    return readFileAsTextAsync(file);
  }

  private async createCopy() {
    if (!this.file) {
      return;
    }
    const contents = await this.readFileAsync(this.file);
    this.loading = true;
    try {
      const newQuestionnaire = replaceIds<Questionnaire>(JSON.parse(contents));
      newQuestionnaire.name = this.copyNewName;
      await this.crud.addAsync([newQuestionnaire]);
      this.copyNewName = "";
      this.file = null;
      this.$emit("update:show", false);
      this.$emit("updated");
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
