


































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import QuestionnaireCopyDialog from "@/components/dialogs/CopyQuestionnaireDialog.vue";
import ImportQuestionnaireDialog from "@/components/dialogs/ImportQuestionnaireDialog.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import { Questionnaire } from "@/data/models/Questionnaires";
import ICrudClient from "@/lib/ICrudClient";
import { saveAs } from "file-saver";

@Component({
  components: {
    ModelTable,
    QuestionnaireCopyDialog,
    ImportQuestionnaireDialog,
  },
})
export default class QuestionnaireTable extends Vue {
  @Prop()
  public provider!: DataProvider<Questionnaire>;

  @Prop()
  public crud!: ICrudClient<Questionnaire>;

  @Prop()
  public columns!: any[];

  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;
  private showMenu = false;
  private prescreeningQuestionnaire: any = null;

  private selected: any[] = [];

  private copyDialog = false;
  private additionalDialog = false;
  private importDialog = false;
  private copyNewName = "";
  private copyQuestionnaire: any = null;

  private prescreening = false;
  private prescreeningDialog = false;

  private async setAsPrescreening() {
    this.prescreening = true;
    const questionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
      this.prescreeningQuestionnaire.id
    );
    questionnaire.isActivePrescreening = true;
    await this.crud.saveAsync(questionnaire);
    this.prescreening = false;
    this.prescreeningDialog = false;
    (this.$refs.modelTable as any).refresh();
  }
  private async setAsAdditional() {
    this.prescreening = true;
    const questionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
      this.prescreeningQuestionnaire.id
    );
    questionnaire.isAdditionalBasicInformation = true;
    await this.crud.saveAsync(questionnaire);
    this.prescreening = false;
    this.additionalDialog = false;
    (this.$refs.modelTable as any).refresh();
  }

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }

  private async download(item: any) {
    const res = await this.$service.providers.questionnaires.fetchItemAsync(
      item.id
    );
    saveAs(
      new Blob([JSON.stringify(res)], { type: "application/text" }),
      item.name + ".txt"
    );
    this.$proxy["showMenu" + item.id] = false;
  }
}
